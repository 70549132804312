<template>
  <div class="content">
    <customer-detail></customer-detail>
  </div>
</template>

<script>
import CustomerDetail from "@/components/customer-detail/CustomerDetail"
import { GET_UX_JOURNEY_CONFIG } from "@/store/common-variables/actions";
import { LP_TYPE } from "@/config";

export default {
  name: "Index",
  components: {
    CustomerDetail
  },
  async mounted() {
    await this.$store.dispatch(GET_UX_JOURNEY_CONFIG, LP_TYPE);
  },
}
</script>

<style lang="scss">
.content {
  color: #404040;
}
</style>
